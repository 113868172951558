import { render, staticRenderFns } from "./yearly_area_compare.vue?vue&type=template&id=039b1bae&scoped=true"
import script from "./yearly_area_compare.vue?vue&type=script&lang=js"
export * from "./yearly_area_compare.vue?vue&type=script&lang=js"
import style0 from "./yearly_area_compare.vue?vue&type=style&index=0&id=039b1bae&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "039b1bae",
  null
  
)

export default component.exports